<template>
  <section class="configurator">
    <header class="hero-small">
      <div>
        <h1>Notre configurateur créé un nouveau <br/> parcours d'achat maison</h1>
      </div>
    </header>
    <main class="grid-container">
      <section class="intro">
        <div class="intro-content grid-x">
          <div class="cell medium-6 small-12">
            <img src="~@/assets/img/pages/macbook.png"/>
          </div>
          <div class="cell medium-6 small-12 intro-text">
            <h2>Un projet dynamique initié en ligne</h2>
            <p>Le configurateur du site Maisons Alysia permet de combiner automatiquement une maison avec un terrain, un terrain avec une maison… tout en affichant instantanément le prix total du projet en ligne. L’interface est simple et intuitive, et le configurateur intègre sur une seule page toutes les fonctionnalités nécessaires à la constitution de votre projet.</p>
          </div>
        </div>
        <div class="carousel">
          <div class="carousel-arrow-left" @click="previousSlide">
            <img src="~@/assets/img/pages/configurator-carousel/left.svg"/>
          </div>
          <div class="slides-container" @touchstart="onTouchStart" @touchend="onTouchEnd">
            <div class="slides" :style="{ '--current-slide': currentSlide - 1 }">
              <div class="slide slide-1">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>1</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Sélectionnez votre lieu de construction et le terrain de votre choix et accédez à notre catalogue de maisons correspondant au style de la zone géographique correspondante</p>
                </div>
              </div>
              <div class="slide slide-2">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>2</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Si vous possédez déjà votre propre terrain, renseignez simplement le configurateur qui l’utilisera pour créer votre projet personnalisé avec la maison de votre choix</p>
                </div>
              </div>
              <div class="slide slide-3">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>3</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Choisissez votre modèle de maison filtrable par nombre de chambres, salles de bains, budget, surface...</p>
                </div>
              </div>
              <div class="slide slide-4">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>4</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Ca y est, votre projet est créé !</p>

                  <p>Vous disposez d’un module maison très complet qui offre des visuels 3D en haute résolution</p>
                </div>
              </div>
              <div class="slide slide-5">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>5</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Accédez à la configuration de votre projet où vous disposez de toutes les caractéristiques et des paramètres de personnalisation : terrain, surface modulable, options et équipements, les prix y compris les frais annexes</p>
                </div>
              </div>
              <div class="slide slide-6">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>6</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>La configuration de base de votre maison propose des options modifiables, soit en plus-values, soit en moins-values. Vous avez accès à toutes les options d’équipements et de finitions en fonction du pack choisi.</p>
                </div>
              </div>
              <div class="slide slide-7">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>7</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Vous pouvez changer de terrain dans la liste des lots et terrains compatibles avec votre modèle de maison. Vous pouvez aussi télécharger le plan de lotissement pour visualiser sa configuration et choisir plus facilement votre lot.</p>
                </div>
              </div>
              <div class="slide slide-8">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>8</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                  <p>Vous avez la possibilité de choisir la surface de votre maison à l’aide du curseur dans le volet “surfaces”.</p>
                </div>
              </div>
              <div class="slide slide-9">
                <div class="slide-top">
                  <div class="slide-number">
                    <h3>9</h3>
                  </div>
                </div>
                <div class="slide-bottom">
                        <p>Découvrez instantanément votre future mensualité sur la base de votre projet, tel que vous l’avez paramétré. Notre calculateur de financement intègre l’éligibilité de votre foyer au Prêt à Taux Zéro (PTZ+). Une exclusivité Maisons Alysia !</p>
                    </div>
                  </div>
                  <div class="slide slide-10">
                    <div class="slide-top">
                      <div class="slide-number">
                        <h3>10</h3>
                      </div>
                    </div>
                    <div class="slide-bottom">
                        <p>Allez plus loin dans votre démarche en contactant votre agence Maisons Alysia et prenez RDV. Ou encore téléchargez le descriptif complet de votre projet pour aller le comparer chez d’autres constructeurs. Un service unique en France...</p>
                  </div>
                </div>
                <div class="slide slide-11">
                  <div class="slide-top">
                    <div class="slide-number">
                      <h3>11</h3>
                    </div>
                  </div>
                  <div class="slide-bottom">
                      <p>Enfin, bénéficiez sur la plateforme Maisons Alysia d’un espace personnel pour sauvegarder vos projets avec tous vos choix et options ainsi que vos données budgétaires et financières</p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-arrow-right" @click="nextSlide">
            <img src="~@/assets/img/pages/configurator-carousel/right.svg"/>
          </div>
        </div>
      </section>
      <section class="transparency">
        <div class="transparency-content grid-x">
          <div class="cell medium-6 small-12 transparency-text">
            <h2>La transparence d’un prix tout compris, même les frais annexes</h2>
            <p>Avec ce logiciel très performant, Maisons Alysia dote les particuliers internautes d’un outil d’aide à la décision inédit sur le marché de la construction de la maison individuelle. Vous obtenez ainsi, en toute transparence, le budget détaillé de votre projet comprenant :</p>
            <ul>
              <li>Coût de la maison</li>
              <li>Options et équipements hors contrat</li>
              <li>Coût du terrain</li>
              <li>Frais de notaire sur le terrain</li>
              <li>Assurance Dommages-Ouvrage</li>
              <li>Taxe de raccordement à l’égout</li>
              <li>Taxe d’aménagement</li>
              <li>Frais d’ouverture des compteurs</li>
              <li>Frais de garantie hypothécaires sur prêts </li>
            </ul>
          </div>
          <div class="cell medium-6 small-12">
            <img src="~@/assets/img/pages/iphones.png"/>
          </div>
        </div>
      </section>
      <section class="description">
        <div class="description-content grid-x">
          <div class="cell medium-6 small-12 description-illu">
            <img src="~@/assets/img/pages/brochure.png"/>
          </div>
          <div class="cell medium-6 small-12 description-text">
            <h2>Un descriptif complet à télécharger</h2>
            <p>Une fois votre projet finalisé sur la page du configurateur, vous avez la possibilité de télécharger le descriptif complet de votre projet. Voici de quoi le descriptif est composé :</p>
            <ul>
              <li>La perspective extérieure</li>
              <li>Le plan</li>
              <li>La distribution (superficies et cotes)</li>
              <li>Le descriptif du terrain</li>
              <li>Les ouvrages et fournitures</li>
              <li>Les options</li>
              <li>Les aménagement optionnés</li>
              <li>Le récapitulatif budgétaire</li>
              <li>Votre agence Alysia</li>
            </ul>
          </div>
        </div>
      </section>
      <section class="after">
        <div class="after-content grid-x">
          <div class="cell medium-6 small-12">
            <h2>et après ?</h2>
            <p>Une fois ce descriptif en poche, Maisons Alysia vous invite à aller comparer ce projet avec ceux de la concurrence.</p>
          </div>
          <div class="cell medium-6 small-12">
            <p>Un conseil : comparez ce qui est comparable et exigez des autres constructeurs une étude aussi poussée que la nôtre.</p>
            <p>Après, c’est notre pari, nous pensons vous revoir physiquement chez Maisons Alysia, dans l’une de nos agences, pour entamer la suite de votre projet de construction !</p>
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
const NB_SLIDES = 11;

export default {
  name: 'Configurator',
  data() {
    return {
      currentSlide: 1,
      touchStartX: 0,
    };
  },
  methods: {
    previousSlide() {
      if (this.currentSlide > 1) {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      if (window.screen.width > 768) {
        if (this.currentSlide < (NB_SLIDES - 2)) {
          this.currentSlide += 1;
        }
      } else if (this.currentSlide < NB_SLIDES) {
        this.currentSlide += 1;
      }
    },
    onTouchStart(event) {
      this.touchStartX = event.changedTouches[0].clientX;
    },
    onTouchEnd(event) {
      const touchEndX = event.changedTouches[0].clientX;
      const touchDiff = touchEndX - this.touchStartX;
      if (touchDiff > 50) {
        this.previousSlide();
      } else if (touchDiff < -50) {
        this.nextSlide();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.configurator
  background-color: $medium-bg
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/happy-family-sitting-couch-using-online-app-tablet.png)
    @media (max-width: 768px)
      background-position: right 40% center
  .grid-container
    margin: 0
    padding: 0
    max-width: 100%
  .intro
    background-image: url(~@/assets/img/pages/dark-background.png)
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 112px
    @media (max-width: 768px)
      margin-top: 0
    .intro-content
      max-width: 1200px
      margin: 0 auto
      align-items: center
      img
        max-width: 100%
      .intro-text
        padding: 0 48px
        box-sizing: border-box
        @media (max-width: 768px)
          padding: 0 24px
        h2
          color: $white
          margin-bottom: 24px
        p
          color: $white
    .carousel
      display: flex
      align-items: center
      margin-top: 72px
      position: relative
      .carousel-arrow-left
        margin-right: 22px
        cursor: pointer
        @media (max-width: 768px)
          position: absolute
          bottom: -48px
          left: 37%
      .carousel-arrow-right
        margin-left: 22px
        cursor: pointer
        @media (max-width: 768px)
          position: absolute
          bottom: -48px
          right: 37%
      .slides-container
        width: calc(318px * 3 + 40px * 3)
        overflow: hidden
        @media (max-width: 1150px)
          width: calc(318px * 2 + 40px * 2)
        @media (max-width: 768px)
          width: 100vw
        .slides
          display: flex
          width: calc(318px * 11 + 40px * 11)
          transform: translateX(calc(-358px * var(--current-slide)))
          transition: transform 0.5s ease-in-out
          @media (max-width: 768px)
            width: calc(100vw * 11)
            transform: translateX(calc(-100vw * var(--current-slide)))
          .slide
            background: $white
            width: 318px
            margin: 0 20px
            border-radius: 8px
            overflow: hidden
            @media (max-width: 768px)
              width: 90vw
              margin: 0 auto
            .slide-top
              height: 210px
              position: relative
              background: $primary
              .slide-number
                background: $primary
                position: absolute
                width: 48px
                height: 48px
                display: flex
                border-radius: 50%
                bottom: -24px
                left: calc(50% - 24px)
                h3
                  color: $white
                  margin: auto
            .slide-bottom
              height: 180px
              padding: 40px 24px 0 24px
              p
                font-size: 14px
                line-height: 20px
                font-weight: 400
            &-1
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-1.png) no-repeat center center
            &-2
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-2.png) no-repeat center center
            &-3
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-3.png) no-repeat center center
            &-4
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-4.png) no-repeat center center
            &-5
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-5.png) no-repeat center center
            &-6
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-6.png) no-repeat center center
            &-7
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-7.png) no-repeat center center
            &-8
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-8.png) no-repeat center center
            &-9
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-9.png) no-repeat center center
            &-10
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-10.png) no-repeat center center
            &-11
              .slide-top
                background: url(~@/assets/img/pages/configurator-carousel/slide-11.png) no-repeat center center
  .transparency
    width: 100%
    display: flex
    flex-direction: column
    padding: 210px 0 124px 0
    @media (max-width: 768px)
      padding-top: 24px
      padding-bottom: 48px
    .transparency-content
      max-width: 1200px
      width: 100%
      margin: 0 auto
      align-items: center
      padding: 0 24px
      box-sizing: border-box
      @media (max-width: 768px)
        flex-direction: column-reverse
      img
        max-width: 100%
      .transparency-text
        h2
          margin-bottom: 24px
        p
          margin-bottom: 24px
        ul
          li
            list-style-image: url("../../assets/img/arrow-blue.svg")
            margin-bottom: 8px
  .description
    background: $black
    width: 100%
    display: flex
    flex-direction: column
    padding: 120px 0
    .description-content
      max-width: 1200px
      margin: 0 auto
      .description-illu
        display: flex
        justify-content: center
        img
          max-width: 100%
        @media (max-width: 768px)
          padding: 0 56px
          box-sizing: border-box
      .description-text
        padding: 0 24px
        box-sizing: border-box
        @media (max-width: 768px)
          margin-top: 40px
        h2
          color: $white
          margin-bottom: 24px
        p
          color: $white
          margin-bottom: 24px
        ul
          li
            list-style-image: url("../../assets/img/arrow-blue.svg")
            margin-bottom: 8px
            color: $white
  .after
    background: $primary
    width: 100%
    display: flex
    flex-direction: column
    padding: 100px 0
    .after-content
      max-width: 1200px
      margin: 0 auto
      h2
        color: $white
        margin-bottom: 16px
      p
        color: $white
        margin-bottom: 24px
      &>div
        box-sizing: border-box
        padding: 0 48px
        @media (max-width: 768px)
          padding: 0 24px
</style>
